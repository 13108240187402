body {
    font-family: 'Nunito Sans', sans-serif !important;
    /* padding-bottom: 60px; */
}

.package-box{
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid blue;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: blue transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.table-container {
    max-width: 100%;
    overflow-x: scroll;
}

.login-form-container {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
}

.form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.justify-around {
    justify-content: space-around;
}

.quotation_container {
    padding-top: 1rem;
}

.single_quote {
    margin-bottom: 1rem;
    background-color: #fff;
    padding: 0.5rem;
    margin-inline: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 #0000000f;
}

.courier_service_logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    object-position: center;
}

.single_quote p {
    font-size: 14px;
    margin-bottom: 8px;
    ;
}

.booknow_container {
    text-align: center;
    padding-top: 1rem;
}

/*-----------03.06.2022------------*/
table {
    font-size: 14px;
}

.btn {
    margin: 2px;
    border: none;
}

.btn-primary {
    border: none;
    background-color: #7367f0;
}

.btn-primary:hover {
    border: none;
    background-color: #594ed5;
    color: #ffffff;
}

.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(243, 242, 255, 0.12);
}

.table,
.card-body>.table {
    color: #494850;
    margin-bottom: 10px;
}

.content-wrapper>.content {
    padding: 1rem;
}

.btn-danger {
    color: #ffffff;
    background-color: rgba(234, 84, 85, 1);
}

.btn-danger:hover {
    color: #fff;
    background-color: rgba(234, 84, 85, 1);
    border: none;
}

.pagination button {
    background-color: #7367f0;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: none;
    color: #ffffff;
    font-size: 16px;
    padding: 0;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
    font-weight: bold;
}

.pagination button:hover {
    background-color: #5b4fd9;
    color: #fff;
}

.pagination select {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    color: #fff;
    padding: .35rem 1.25rem;
    border: none;
    border-radius: 5px;
    font-size: 14px;
}

.pagination option {
    background: #fff;
    color: #000;
    font-size: 14px;
}

.pagination span {
    font-size: .857rem;
    color: #a09ab3;
  }

.pagination input {
    padding-top: .188rem;
    padding-bottom: .188rem;
    border: 1px solid #d8d6de;
    border-radius: 5px;
    width: 50px;
}

.card-header>.card-tools {
    margin-right: 0;
}

.navbar-light .navbar-nav .nav-item select.nav-link {
    background-color: #17a2b8 ;
    color: #ffffff;
    border: none;
    border-radius: 5px;
}

.navbar-light .navbar-nav .nav-item select.nav-link option {
    background-color: #00cfe8;
    color: #ffffff;
}

.modal .modal-title {
    font-weight: 500;
    font-size: 1.185rem;
    color: #5e5873;
}

.form-group label {
    color: #5e5873;
    font-size: 13px;
    margin-bottom: 0;
    font-weight: normal;
}

.form-group input,
.form-group select,
.form-group textarea {
    padding: 0 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: .357rem;
    font-size: 13px;
    height: 34px;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link.log_out_btn {
    color: #ffffff;
    background-color: #6418c3;
    margin-left: 15px;
    margin-right: 10px;;
    border-radius: 5px;
}

.table td a {
    background-color: rgba(40, 199, 111, .12);
    color: #28c76f;
    padding: .3rem .5rem;
    text-align: center;
    border-radius: .358rem;
}
.table td a.sjduusy{
    color: #0da450 !important;
}

.success_text {
    text-align: center;
    padding: 5rem 0;
}

.success_text h3 {
    font-size: 24px;
}

.wallet-balance h1 {
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    line-height: 40px;
    font-weight: 300;
    padding: 3rem 0;
    background-color: #3b3363;
    border-radius: .5rem;
    width: 325px;
    margin: 0 auto;
    position: relative;
}
.wallet-balance h1 img{
    position: absolute;
    top: -25px;
    left: 132px;
    width: 60px;
}

.wallet-balance h1 span {
    font-size: 36px;
    font-weight: 700;
}

.mt-50 {
    margin-top: 50px;
}

.yuhfg .row {
    justify-content: space-around;
}

.pt-50 {
    padding-top: 50px;
}

.pt-75 {
    padding-top: 75px;
}

.yuhfg a {
    background-color: #fcc417;
    color: #ffffff;
    border: 1px solid #fcc417;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: 600;
    text-transform: none;
    text-decoration: none;
    transition: 0.3s;
    border-radius: 6px;
    display: inline-block;
}
.mb-30 {
    margin-bottom: 30px;
}

.heading-h2 {
    font-size: 36px;
    font-weight: 700;
    color: #192964;
    margin-bottom: 15px;
}

.brndtl {
    text-align: center;
}

._brndtl h4 {
    font-size: 40px;
    font-weight: 700;
    color: #ed1d24;
    margin: 0;
    margin-bottom: 5px;
}

.psty-1 {
    font-size: 16px;
    font-weight: 400;
    color: #00000099;
}

.middle-section {
    background-color: #192964;
    color: #192964;
    text-align: center;
}

.w-25 {
    width: 25%;
}

.p-tb-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.p-lr-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.middle-section h2 {
    font-size: 44px;
    font-weight: 600;
}

.middle-section h3 {
    font-size: 20px;
    font-weight: 300;
}

._owlin {
    text-align: center;
    padding: 15px;
}

._owlin img {
    border-radius: 50%;
    /*border: 4px solid #192964;*/
    box-shadow: 0 0 15px 0 #00000050;
}

._owlin h4 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;
    color: #192964;
}
._owlin p{
    font-size: 12px;
    font-weight: 200;
    color: #454545;
    padding-right: 15px;
    margin-bottom: 0;
}

.wid-33 {
    width: 33%;
    border-right: 1px solid #ffffff;
}

.wid-33:last-child {
    border-right: none;
}

.ftr1 span {
    margin: 0;
}

.ftr2,
.ftr1,
.ftr3 {
    text-align: center;
}

.ftr4 {
    text-align: right;
}

.ftr1 img,
.ftr2 img,
.ftr3 img,
.ftr4 img {
    width: 30px;
}

.sidebar-dark-primary {
    background-color: #3b3363;
}

.sidebar-dark-primary .sidebar a {
    color: #ffffff;
    font-size: 14px;
}

.nav-sidebar .nav-item>.nav-link i {
    margin-right: 14px;
    width: 25px;
    text-align: center;
    font-size: 16px;
}

.sidebar-dark-primary .brand-link {
    border-bottom: 1px solid #fff;
}

.sidebar-dark-primary .user-panel {
    border-bottom: 1px solid #fff;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #ffffff;
    color: #1c90fd;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active {
    box-shadow: none;
}

.form-group {
    margin-bottom: 8px;
}

.modal-header,
.modal-body {
    padding: 10px 15px;
}

.bold_font {
    font-weight: 700;
    color: #0dc1aa;
}

.single_quote {
    align-items: center;
}

.quotation_container .modal .modal-title {
    font-weight: 500;
    font-size: 14px;
    color: #5e5873;
}

.are-you-sure {
    color: #5e5873;
    font-size: 20px;
    font-weight: 500;
}

.are-you-sure-text {
    color: #5e5873;
    font-size: 14px;
    font-weight: 500;
}

.book-btn-width .btn {
    width: 80px;
}

.quotation_container .modal-dialog {
    margin: 5rem auto;
}

.quotation_container .modal-header {
    padding: 30px 15px 15px 15px;
    position: relative;
}

.quotation_container .btn-close {
    position: absolute;
    right: 15px;
    top: 8px;
}

.singup-icon {
    height: auto;
    width: 170px;
    object-fit: contain;
    object-position: center;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 #00000030;
    margin-bottom: 40px;
    padding: 2px;
}

.form-signin .form-floating > .form-control {
    width: 100%;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0 0 0 5px;
    height: 40px;
    background: transparent;
    border-bottom: 2px solid #b5b5b5;
  }

.form-signin .form-floating > label {
    font-weight: 500;
    color: #333333;
    font-size: 14px;
    padding: 10px;
    margin: 0;
  }

.form-signin .h3 {
    font-weight: 700;
    font-size: 20px;
    color: #5e5873;
}

.bg-1d5b44 {
    background: #192964;
}

.navbar-brand ._logo {
    width: 140px;
    padding: 2px;
    background-color: #fff;
}

.icon-box img {
    width: 80%;
}

._owlin img {
    width: 85px;
}

.fsdghsd {
    background-color: #fcc417;
    color: #ffffff;
    border: 1px solid #fcc417;
    padding: 7px 20px;
    font-size: 15px;
    font-weight: 600;
    text-transform: none;
    text-decoration: none;
    transition: 0.3s;
    border-radius: 6px;
}

.individual_item {
    background-color: aliceblue;
    padding: 1rem;
    border-radius: 1rem;
}

.top-section {
    position: relative;
}

.background-section {
    min-height: 600px;
}

.background-overflow {
    width: 100%;
    position: absolute;
    top: 1px;
    z-index: 1;
}

.overflow-text {
    min-height: 600px;
}

.lowlsld .slick-dots,
.lowlsld .slick-next,
.lowlsld .slick-prev {
    display: none !important;
}
.new-h2-sty1 {
    font-size: 48px;
    font-weight: 600;
    color: #ed1d24;
    margin-bottom: 15px;
    line-height: 44px;
}

.p-sty1 {
    font-size: 22px;
    line-height: 26px;
    color: #363636;
    margin-bottom: 20px;
    font-weight: 300;
}

.inner-page-content {
    margin-bottom: 60px;
    margin-top: 60px;
}

.inner-left-image-text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 400px;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow: hidden;
    border-radius: 5px;
}

.inner-left-image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.inner-left-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.inner-left-text {
    width: 45%;
    padding: 25px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #ffffff;
    position: relative;
}

.left-sth21 {
    color: #ed1d24;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 15px;
    font-weight: 400;
}

._palsf2 {
    padding-left: 0;
    padding-top: 0;
}

._palsf2 p {
    font-weight: normal;
    font-size: 15px;
    color: #000000;
    margin-bottom: 15px;
    line-height: 24px;
    padding-right: 0px;
    text-align: left;
    font-weight: 300;
} 
.bl-2 {
    border-left: 2px solid #ff1519;
}
.bg-light-gray {
    background-color: #f1f1f1;
}
.img-fluid.imagesppp{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.image-qwe {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
}
.ft_logo {
    width: 80px;
    padding: 2px;
    background-color: #ffffff;
}
.inner-banner {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}
.video-sty1 iframe {
    width: 100%;
    height: 320px;
}
.contact-page {
    width: 100%;
}
.contact-form {
    width: 100%;
}
.my-row {
    display: flex;
    flex-wrap: wrap;
}
.contact-form .form-control {
    margin-bottom: 20px;
    background-color: #ffffff;
    font-size: 15px;
    height: 50px;
}
.contact-form textarea.form-control {
    height: 100px;
}
.footer-style {
    
    width: 100%;
    bottom: 0;
    z-index: 999;
}
.contact-mp iframe{
    width: 100%;
    height: 400px;
}
.lkhhgf{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    padding-top: 55px;
    padding-bottom: 55px;
}
.big-h2-sty{
    font-size: 90px;
    line-height: 85px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 20px;
}
.asdfsgh img{ 
    width: 100%;
    height: 380px;
    object-fit: cover;
    object-position: center;
}
.z-index1{
    z-index: 1;
}
.content-wrapper {
    background-color: #2c254a;
  }
.wrapper .card{
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 #464646;
}  
/* .wrapper .table, .wrapper .card-body > .table {
    color: #fff;
    border: 1px solid #ffffff;
  }
.wrapper .table-striped > tbody > tr:nth-of-type(2n+1) > *{
    color: #ffffff;
  } */
.main-footer{
    background-color: #343a40;
    border-color: #4b545c;
}
.alert-warning {
    color: #f00;
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    font-size: 14px;
  }
  /* .sidebar-dark-primary .user-panel{
    border-bottom: none;
    display: none !important;
  }   */
  .form-signin .form-floating > .form-control:focus ~ label, .form-signin .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-signin .form-floating > .form-select ~ label {
    opacity: .65;
    transform: scale(.75) translateY(-1.4rem) translateX(-.5rem);
  }
  .form-signin .form-floating > .form-control:autofill ~ label {
    opacity: .65;
    transform: scale(.75) translateY(-1.4rem) translateX(-.5rem);
  }
  .individual_item .form-group input, .individual_item .form-group select, .individual_item .form-group textarea{
    padding: 0 10px;;
  }
  .css-319lph-ValueContainer{
    padding: 0 8px !important;
  }
  .css-6j8wv5-Input{
    margin: 0 !important;
    padding: 0 !important;
  }
  .css-6j8wv5-Input input{
    height: 30px;
  }
  .css-tlfecz-indicatorContainer{
    padding: 5px !important;
  }
  .css-1s2u09g-control{
    min-height: 34px !important;
    font-style: 14px !important;
  }
  .close-itemsaa{
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
  }
  .jhgfds .btn{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0;
    line-height: 26px;
  }
  .apply-cp-code{
    width: 300px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .cpn-cd-bg{
    background-color: #ffffff;
    width: 350px;
    padding: 30px;
    margin: 0 auto;
  }
  .feature-bottom-s{
    background-attachment: fixed;
    background-position: center;
    width: 100%;
    min-height: 450px;
    display: flex;
    align-items: center;
    text-align: center;
    background-image: url(http://localhost:3000/image7.jpg);
    position: relative;
    padding: 100px 0;
  }
  .feature-bottom-s:before{
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    background-color: #00000080;
    left: 0;
    top: 0;
  }
  .new-h2-sty2{
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: 32px;
  }
  .contact-image img{
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
  .wid-200{
    width: 200px;;
  }
  .pad-t-58{
    padding-top: 58px;
  }
  .pad-b-58{
    padding-bottom: 58px;
  }
  .sidebar-mini.sidebar-collapse .main-footer{
    margin-left: 0 !important;
  }
  .ml-4p5rem{
    margin-left: 4.5rem;
  }
  .h-100vh{
    height: 100vh;
  }
  .wrapper .navbar-dark, .wrapper .main-footer{
    background-color: #3b3363;
    border-color: #3b3363;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,1);
  }
  /* .gcfscc{
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 30px;
    z-index: 2;
  } */
  .fs-18b{
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .col-sm-24{
    flex: 0 0 20%;
    max-width: 20%;
  }
  .dash-card-style{
    color: #ffffff;
    text-align: center;
    font-size: 20px;
    line-height: 34px;
    font-weight: 300;
    padding: 3.5rem 0 3rem 0;
    background-color: #3b3363;
    border-radius: .5rem;
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-transform: capitalize;
  }
  .dash-card-style span.bcnfht{
    font-size: 36px;
    font-weight: 700;
  }
  .dash-chart-text span{
    font-size: 14px;
  }
  .dash-card-style img  {
    position: absolute;
    top: -35px;
    left: 31%;
    width: 70px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    background-color: #ffffff;
  }
  @media only screen and (min-width: 1440px) and (max-width: 1899px){
    .dash-card-style img{
        left:35%;
    }
  }
  @media only screen and (min-width: 1900px) and (max-width: 6000px){
    .dash-card-style img{
        left: 39.5%;
    }
  }
  .ghfdsh th, .ghfdsh td{
    line-height: 18px;
  }
  .ap-ratio-2{
    aspect-ratio: 2;
  }
  .dash-card-style .table-striped > tbody > tr:nth-of-type(2n+1) > *{
    color: #ffffff;
  }
  .ghfdsh{
    width: 100%;
    height: 100%;
    border: 1px solid #ffffff;
  }
  .dash-card-style tspan {
    color: #fff !important;
    font-size: 12px;
    fill: #fff;
  }
  .recharts-tooltip-wrapper {
    font-size: 14px;
    line-height: 14px;
  }
  .recharts-default-tooltip {
    padding-top: 0 !important;
  }
  .dash-news a{
    color: #ffffff;
    font-size: 15px ;
    font-weight: 700;
  }
  .brand-text {
    font-size: 15px;
    position: relative;
    top: -3px;
}
.wrapper .brand-link .brand-image {
    float: left;
    line-height: .8;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    max-height: 25px;
    width: auto;
}
.sidebar-mini.sidebar-collapse .sidebar .user-panel > .info {
    margin-left: -10px;
    animation-name: fadeOut;
    animation-duration: .3s;
    animation-fill-mode: none;
    visibility: visible;
}
  
  @media only screen and (min-width: 1200px) and (max-width: 1500px){
    .dash-card-style span.bcnfht{
        font-size: 30px;
    }
  }  
.form-check-label {
    font-size: 11px;
    line-height: 15px;
}
.form-check-input{
    width: 14px;
    height: 14px;
    top: 1px;
    margin-left: -5px;
}
.dtdc_ad_section{
    text-align: center;
}
.dtdc_ad_section img{
    margin-top: 50px;
    width: 100%;
}
.doc-btn{
    margin: 0;
    background-color: #ff980f;
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    margin-top: 1rem;


}
.line-height-auto{
    line-height: normal;
}
.enter-csv .form-control {
    height: 34px;
    padding: 6px 12px;
}
.settings-btn{
    background-color: #7fa5c9;
    color: #ffffff;
}
.sftp-submit{
    width: calc(100% - 250px);
}
.form-group .smtp-input-checkbox{
    display: inline-block;
    margin-right: 5px;
    width: 15px;
    height: 15px;
}
.collps-d{
    background-color: #5ab5b6;
    width: 100%;
    color: #ffffff;
    text-align: left;
    position: relative;
}
.collps-d:before{
    position: absolute;
    content: ">";
    right: 8px;
    top: 8px;
    font-weight: bolder;
    transform: rotate(90deg);
}
.sugesstion {
    position: absolute;
    background: #ffffff;
    width: 97.5%;
    z-index: 1;
    padding: 10px;
    font-size: 12px;
    margin-top: -10px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 5px 10px 0 #00000020;
    min-height: 1px;
    max-height: 200px;
    overflow-x: auto;
}
.sugesstion p{
    margin-bottom: 3px;
    cursor: pointer;
}
.wrapper .content-wrapper {
    min-height: calc(100vh - calc(0rem) - calc(3.5rem + 1px));
}
.tb-logo img{
    width: 15px;
    border-radius: 3px;
}
.tb-logo{
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 10px;
    color: #ffffff;
}

.order-table-fix-w table{
    width: 100%;
}
.order-table-fix-w table tr th:first-child, .order-table-fix-w table tr td:first-child{
    width: 3%;
}
.order-table-fix-w table tr th:nth-child(2), .order-table-fix-w table tr td:nth-child(2) {
    width: 17%;
}
.order-table-fix-w table tr th:nth-child(3), .order-table-fix-w table tr td:nth-child(3) {
    width: 5%;
}
.order-table-fix-w table tr th:nth-child(4), .order-table-fix-w table tr td:nth-child(4) {
    width: 12%;
}
.order-table-fix-w table tr th:nth-child(5), .order-table-fix-w table tr td:nth-child(5) {
    width: 12%;
}
.order-table-fix-w table tr th:nth-child(6), .order-table-fix-w table tr td:nth-child(6) {
    width: 8%;
}
.order-table-fix-w table tr th:nth-child(7), .order-table-fix-w table tr td:nth-child(7) {
    width: 12%;
}
.order-table-fix-w table tr th:nth-child(8), .order-table-fix-w table tr td:nth-child(8) {
    width: 10%;
}
.order-table-fix-w table tr th:nth-child(9), .order-table-fix-w table tr td:nth-child(9) {
    width: 10%;
}
.order-table-fix-w table tr th:last-child, .order-table-fix-w table tr td:last-child {
    width: 10%;
}
.track_bg{
    background-color: #ffe2bb;
    color: #000000;
}
body:not(.layout-fixed) .main-sidebar{
    position: fixed;
}
.order-table-fix-w table th{ 
    position: sticky;
    top: 0;
    z-index: 1; 
    background: #fff; 
}
.ab-footer{
    margin-bottom: 50px;
}
.isgfff{
    font-size: 22px;
    font-weight: 700;
    color: #ed1d24;
    margin-bottom: 15px;
}
.isgfff2{
    font-size: 18px;
    font-weight: 700;
    color: #ed1d24;
    margin-bottom: 25px;
}
.hvgvg{
    font-size: 30px;
    color: #222222;
    font-weight: 700;
    line-height: 16px;
}
.hvgvg span{
    font-size: 14px;
    font-weight: 300;
    color: #999999;
}
.box-boby-text ul{
    padding: 0 15px;
    list-style-type: none;
}
.box-boby-text ul li{
    font-size: 15px;
    font-weight: 400;
    color: #454545;
    line-height: 18px;
    margin-bottom: 15px;
    text-align: left;
}
.box-boby-text ul li img{
    width: 14px;
    display: inline-block;
    margin-right: 5px;
}
.box-imgss{
    text-align: center;
}
.box-imgss img{
    width: 100px;
    margin-bottom: 15px;
}
.hjfsady {
    padding: 15% 12%;
    margin-right: -100px;
    border-radius: 65px 0 65px 0;
    border: 2px solid #192964;
}
.hjfsady {
    padding: 15% 12%;
    margin-right: -100px;
    border-radius: 0;
    border: 2px solid #192964;
    background: #192964;
    color: #fff !important;
    position: relative;
}
.ghsdss{
    min-height: 318px;
}
.background-overflow{
    width: 100%;
    height: 700px;
    display: flex;
    align-items: flex-end;
}
.middle-section{
    position: relative;
    height: 450px;
}
.mid-image{
    width: 100%;
    height: 450px;
    object-fit: cover;
    position: absolute;
    left: 0;
    object-position: top;
}
.zindex-2{
    z-index: 2;
}
.vhfjhf{
    height: 200px;
    background-color: #0000007a;
}
.long-imga{
    width: 100%;
    height: 640px;
    object-fit: cover;
    object-position: center;
}
.wswfzs .new-h2-sty1 {
    font-size: 20px;
    color: #192964;
    line-height: 24px;
}
.wswfzs .p-sty1 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    text-align: justify;
}
.min-h-315{
    min-height: 295px;
}
.wswfzs .border {
    border-color: #192964 !important;
}
.dhfxcfgb{
    list-style-type: none;
    padding: 0;
}
.dhfxcfgb li a{
    color: #ffffff;
}
.f-box-img img{
    width: 40px;
    margin-bottom: 15px;
}
.f-box-text h2{
    color: #6d768b;
    font-size: 16px;
    font-weight:700;
    margin-bottom: 0;
}
.f-box-text p{
    color: #869ab8;
    font-size: 14px;
    font-weight: 300;
    margin-top: 15px;
}
.f-mid{
    padding: 80px 0;
}
.f-head{
    color: #161c2d;
    font-size: 42px;
    margin-bottom:30px;
    font-weight:700;
    text-align:center;
    text-transform:capitalize;
}
.top-section .yuhfg {
    margin-bottom: 175px;
}



@media only screen and (min-width: 1141px) and (max-width: 1366px){
    .order-table-fix-w table{
        font-size: 11px;
    }
    .order-table-fix-w table .doc-btn{
        font-size: 10px;
        margin-top: 0;
        margin-bottom: 5px;
        margin-left: 0;
    }
    .order-table-fix-w .table td a.sjduusy{
        font-size: 9px;
    }
    .isgfff {
        font-size: 16px;
    }
    .box-boby-text ul {
        padding: 0;
    }
    .box-boby-text ul li {
        font-size: 13px;
    }
    .long-imga {
        width: 100%;
        height: 725px;
    }
    .background-overflow{
        height: 500px;
    }
    .img-fluid.imagesppp {
        height: 500px;
    }
    .background-overflow .new-h2-sty1{
        width: 47%;
        margin-left: auto;
    }
    .background-overflow .p-sty1 {
        font-size: 18px;
    }
    .feature-page .background-overflow .new-h2-sty1{
        margin-left: 0;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1140px){
    .order-table-fix-w table{
        font-size: 8px;
    }    
    .order-table-fix-w table .doc-btn{
        font-size: 10px;
        margin-top: 0;
        margin-bottom: 5px;
        margin-left: 0;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1366px){
    .gcfscc{
        padding-top: 40px;
    }
    p.fs-18b{
        font-size: 15px !important;
        font-weight: 700 !important;
    }
    .new-h2-sty2 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 8px;
        line-height: 42px;
    }
    .gcfscc p{
        width: 100% !important;
    }
    ._brndtl h4 {
        font-size: 28px;
    }
    .left-sth21{
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .overflow-text {
        min-height: 450px;
    }
    .background-section {
        min-height: 1px;
    }
    .big-h2-sty {
        font-size: 52px;
        line-height: 56px;
    }
    .feature-bottom-s{
        padding: 50px 0 100px 0;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
    .overflow-text {
        min-height: 375px;
    }
    .background-section {
        min-height: 1px;
    }
    .inner-left-image {
        position: relative;
    }
    .inner-left-image-text{
        display: block;
    }
    .inner-left-image-text{
        padding-top: 0;
        padding-bottom: 0;
    }
    .inner-left-text {
        width: 100%;
    }
    .inner-page-content .align-items-center {
        align-items: start !important;
    }
    .p-lr-30 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .middle-section h2 {
        font-size: 30px;
    }
    .middle-section h3 {
        font-size: 15px;
    }
    .background-section img.imagesppp {
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
    }
    .new-h2-sty1 {
        font-size: 24px;
        margin-bottom: 10px;
        line-height: normal;
    }
    .p-sty1 {
        font-size: 14px;
    }
    .gcfscc {
        padding-top: 25px;
    }
    p.fs-18b{
        font-size: 15px !important;
        font-weight: 700 !important;
    }
    .new-h2-sty2 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 8px;
        line-height: 30px;
    }
    .gcfscc p{
        width: 100% !important;
    }
    .tb-logo {
        position: absolute;
        right: 30px;
        top: 0px;
    }
    .feature11 {
        background: #192964;
        margin-top: 0 !important;
    }
    .lkhhgf {
        position: relative;
    }
    .adfdfh{
        display: none;
    }
    .col-md-4.ps-5.z-index1{
        padding-left: 0 !important;
    }
    .big-h2-sty {
        font-size: 68px;
        line-height: 70px;
    }
    .inner-banner{
        height: 225px;
    }
    .p-sty1{
        width: 90% !important;
    }
    .contact-image img{
        height: auto;
    }
}
@media only screen and (min-width: 300px) and (max-width: 767px){
    .background-overflow{
        position: relative;
        height: auto;
    }
    .background-section img.imagesppp{
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;   
        margin-top: 0;
        height: auto;
    }
    .background-section .col-md-6.pe-0{
        padding-right: 7.5px !important;
    }
    .background-section {
        min-height: 1px;
    }
    .overflow-text {
        min-height: 6px;
    }
    .inner-left-image {
        position: relative;
    }
    .inner-left-image-text{
        display: block;
        padding: 0;
    }
    .inner-left-text{
        width: 100%;
    }
    .left-sth21 {
        font-size: 24px;
        line-height: 30px;
    }
    .inner-page-content .htfdh{
        padding: 15px 30px;
    }
    .p-lr-30 {
        padding-left: 5px;
        padding-right: 5px;
    }
    .middle-section h2 {
        font-size: 28px;
    }
    .middle-section h3 {
        font-size: 14px;
    }
    .p-tb-50 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    ._brndtl h4 {
        font-size: 22px;
    }
    .psty-1 {
        font-size: 14px;
    }
    .long-imga{
        height: auto;
    }
    ._brndtl.p-5.text-center{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    ._owlin h4{
        font-style: 14px;
    }
    .new-h2-sty1 {
        font-size: 18px;
        margin-bottom: 15px;
        line-height: 18px;
        margin-top: 20px;
        text-align: center;
    }
    .row.mb-4.shadow-sm.rounded-sm.bg-white.align-items-center{
        margin: 10px;
    }
    .bg-light-gray .col-md-6.pe-5.ps-5{
        padding: 15px !important;
    }
    .new-h2-sty2{
        color: #ed1d24 !important;
        font-size: 22px;
        line-height: 26px;
    }
    .ab-footer .new-h2-sty2{
        text-align: center;
    }
    .p-sty1{
        color: #363636 !important;
        width: 100% !important;
        font-size: 14px !important;
        font-weight: normal !important;
    }
    .lkhhgf .p-sty1{
        color: #ffffff !important;
    }
    .gcfscc {
        position: relative;
        padding-top: 30px;
    }
    .footer-style{
        position: relative;
    }
    .tb-logo {
        position: relative;
        right: 0;
        top: 0;
        font-size: 10px;
        color: #ffffff;
        text-align: center;
    }
      .feature11 {
          background: #192964;
          margin-top: 0 !important;
    }
      .lkhhgf {
          position: relative;
    }
      .adfdfh{
          display: none;
    }
      .col-md-4.ps-5.z-index1{
          padding-left: 0 !important;
    }
    .big-h2-sty {
        font-size: 46px;
        line-height: 48px;
    }
      .inner-banner{
          height: 225px;
    }
      .p-sty1{
          width: 100% !important;
          line-height: 20px;
          text-align: center;
    }
    .min-h-315{
        min-height: 1px;
    }
    .yuhfg{
        text-align: center !important;
    }
    .yuhfg a{
        font-size: 12px;
    }
    .pe-5.ps-5.mt-5{
        padding: 0 10px !important
    }
    .w-100.text-end.pe-5{
        padding: 0 15px !important;
    }
    
    .lkhhgf .col-md-8{
        padding: 30px 0 0 0;
    }
    .asdfsgh img {
        width: 100%;
        height: auto;
    }
    .col-md-6.pe-5.ps-5{
        padding: 15px !important;
    }
    .video-sty1 iframe {
        width: 100%;
        height: 200px;
    }
    .inner-banner {
        height: auto;
    }
    .lkoijuy .container.mt-5{
        margin-top: 0!important;
    }
    .feature-bottom-s .new-h2-sty2, .feature-bottom-s .p-sty1{
        color: #ffffff!important;
    }
    .contact-image img{
        height: auto;
    }
    .contact-mp iframe{
        height: 300px;
    }
    .dhfxcfgb{
        text-align: center;
    }
    footer{
        text-align: center;
    }
    .mid-image, .middle-section{
        height: 250px;
    }

}
@media only screen and (min-width: 1600px) and (max-width: 1700px){
    .background-overflow{
        height: 600px;
    }
    .top-section .yuhfg {
        margin-bottom: 130px;
    }
}
@media only screen and (min-width: 1500px) and (max-width: 1599px){
    .background-overflow{
        height: 550px;
    }
    .top-section .yuhfg {
        margin-bottom: 115px;
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px){
    .background-overflow{
        height: 515px;
    }
    .top-section .yuhfg {
        margin-bottom: 90px;
    }
}
@media only screen and (min-width: 1300px) and (max-width: 1399px){
    .top-section .yuhfg {
        margin-bottom: 90px;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1299px){
    .top-section .yuhfg {
        margin-bottom: 90px;
    }
}
@media only screen and (min-width: 1100px) and (max-width: 1199px){
    .background-overflow{
        height: 400px;
    }
    .top-section .yuhfg {
        margin-bottom: 75px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px){
    .background-overflow{
        height: 350px;
    }
    .top-section .yuhfg {
        margin-bottom: 60px;
    }
}



.w-100 {
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
}